import Vue from 'vue'
import dataFileScsMixin from '../mixins/data_file_scs_mixin'
import $ from "jquery"
import axios from "../plugins/axios"

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('bulk_assignee-assigned-data-file-scs')
  if (!el) return
  new Vue({
    el: el,
    mixins: [dataFileScsMixin],
  })
})

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('excel-download-assigned-data-file-scs')
  if (!el) return
  new Vue({
    el: el,
    data() {
      return {
        q: {},
        building: false,
        message: "Excelとしてダウンロード",
        progress: 0,
        visible: false
      }
    },
    mounted() {
      const initData = $(this.$el).data('init')
      this.q = initData
    },
    methods: {
      async build() {
        let timer_id = null
        this.$_startBuilding()
        await axios.post(`/assigned_data_file_scs/build/`, {
          q: this.q
        }).then(response => {
          timer_id = setInterval(() => this.$_confirm(response.data.key, () => {
            this.$_endBuilding()
            clearInterval(timer_id)
            this.$_download(response.data.key)
          }, () => {
            this.$_endBuilding()
            clearInterval(timer_id)
          })
          , 3000)
        }).catch(error => {
          this.$_endBuilding()
          if (timer_id != null) {
            clearInterval(timer_id)
          }
        })
      },
      $_confirm(key, callback, failed_callback) {
        axios.get(`/assigned_data_file_scs/download.json?key=${key}`).then(response => {
          this.$_checkProgress(response.data.progress)
          if (response.data.status === 'completed') {
            if (callback != null) { callback() }
          }
        }).catch(error => {
          alert("エラーが発生しました")
          if (failed_callback != null) { failed_callback() }
        })
      },
      $_download(key) {
        location.href = `/assigned_data_file_scs/download.xlsx?key=${key}`
      },
      isProcessing() {
        return this.building
      },
      $_startBuilding() {
        this.building = true
        this.message = "ファイル作成中…"
        this.progress = 0
        this.visible = true
      },
      $_endBuilding() {
        this.building = false
        this.message = "Excelとしてダウンロード"
        this.visible = false
      },
      $_checkProgress(progress) {
        this.progress = progress
      },
    }
  })
})
