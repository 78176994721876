import Vue from 'vue'
import $ from 'jquery'
import axios from '../plugins/axios'
import _ from 'lodash'
import Turbolinks from 'turbolinks'
import { noticeState } from '../state'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('vue-unknown-shop-infos')
  if (!el) return
  new Vue({
    el: el,
    data() {
      return {
        dataFileId: null,
        dataFileScId: null,
        unknownShopInfos: [],
        selectedInfos: [],
        statusToChange: 'none'
      }
    },
    mounted() {
      const initData = $(this.$el).data('init')
      this.unknownShopInfos = initData.unknownShopInfos
      this.dataFileId = initData.dataFileId
      this.dataFileScId = initData.dataFileScId
    },
    methods: {
      toggleSelected(event, targetId) {
        const targetInfo = _.find(this.unknownShopInfos, { id: targetId })
        if (!targetInfo) return
        if (event.target.checked) {
          this.$_addSelectedInfo(targetInfo)
        } else {
          this.$_removeSelectedInfo(targetInfo)
        }
      },
      selectAll() {
        this.selectedInfos = _.cloneDeep(this.unknownShopInfos)
      },
      unselectAll() {
        this.selectedInfos = []
      },
      async registerIgnoreShopInfos() {
        await axios.post(`${this.pageLocation}/register_ignore_shop_infos`, {
          unknown_shop_info_ids: this.selectedInfos.map(c => c.id)
        }).then(() => {
          this.$_visitToPage()
          this.$_noticeMessage('success', '除外ショップリストへの登録が完了しました。')
        }).catch(() => {
          this.$_noticeMessage('danger', 'エラーにより除外ショップリストへの登録ができませんでした。')
        })
      },
      $_addSelectedInfo(targetInfo) {
        if (_.find(this.selectedInfos, { id: targetInfo.id })) return
        this.selectedInfos.push(targetInfo)
      },
      $_removeSelectedInfo(targetInfo) {
        this.selectedInfos = this.selectedInfos.filter(c => c.id !== targetInfo.id)
      },
      $_visitToPage() {
        Turbolinks.visit(this.pageLocation)
      },
      $_noticeMessage(type, message) {
        noticeState.message = message
        noticeState.type = type
        noticeState.isOpen = true
      }
    },
    computed: {
      isInfoSelected() {
        return !_.isEmpty(this.selectedInfos)
      },
      checked() {
        return targetId => {
          return _.find(this.selectedInfos, { id: targetId }) ? 'checked' : null
        }
      },
      pageLocation() {
        return `/data_files/${this.dataFileId}/data_file_scs/${this.dataFileScId}/unknown_shop_infos`
      },
      isExistSelectedInfos() {
        return !_.isEmpty(this.selectedInfos)
      }
    }
  })
})
