import Vue from 'vue'
import { noticeState } from '../state'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('vue-notice')
  if (!el) return
  new Vue({
    el: el,
    methods: {
      closeNotice() {
        noticeState.isOpen = false
      }
    },
    computed: {
      isOpen() {
        return noticeState.isOpen
      },
      noticeType() {
        return noticeState.type
      },
      message() {
        return noticeState.message
      }
    }
  })
})
