import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks'
import './plugins/direct_upload'
import './vue_instances/header'
import './vue_instances/flash'
import './vue_instances/data_file_scs'
import './vue_instances/open_shop_candidates'
import './vue_instances/close_shop_candidates'
import './vue_instances/unknown_shop_infos'
import './vue_instances/notice'
import './vue_instances/assigned_data_file_scs'
import './vue_instances/ignore_shop_infos'

Vue.use(TurbolinksAdapter)
