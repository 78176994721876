import Vue from 'vue'

export const noticeState = Vue.observable({
  type: 'primary',
  message: '',
  isOpen: false
})

export const candidateState = Vue.observable({
  failureInfos: []
})
