import Vue from 'vue'

const ids = Array.from({ length: 5 }, (v,i) => i).map(i => `vue-flash-${i}`)

document.addEventListener('turbolinks:load', () => {
  ids.forEach(id => {
    const el = document.getElementById(id)
    if (el) {
      new Vue({
        el: el,
        data() {
          return {
            isFlashOpen: true
          }
        },
        methods: {
          closeFlash() {
            this.isFlashOpen = false
          }
        }
      })
    }
  })
})
