import Vue from 'vue'
import axios from '../plugins/axios'
import { candidateState } from '../state'
import candidateMixin from '../mixins/shop_candidate_mixin'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('vue-close-shop-candidates')
  if (!el) return
  new Vue({
    el: el,
    mixins: [candidateMixin],
    methods: {
      async registerCloseShop() {
        if (!window.confirm('SCGATEに退店として登録しますか？')) return
        await axios.post(`${this.pageLocation}/register_close_shop`, {
          close_shop_candidate_ids: this.selectedCandidates.map(c => c.id),
          close_date: this.closeDate
        }).then(response => {
          const succeessCount = response.data.successes.length
          const failureInfos = response.data.failures
          candidateState.failureInfos = failureInfos
          const failureCount = failureInfos.length
          let message = `SCGATEへの退店登録が${succeessCount}件完了しました。`
          if (failureCount > 0) {
            message += `\n${failureCount}件登録できませんでした。`
          }
          const noticeType = failureCount > 0 ? 'warning' : 'success'
          this.$_noticeMessage(noticeType, message)
          this.$_visitToPage()
        }).catch(() => {
          this.$_noticeMessage('danger', 'エラーにより退店登録ができませんでした。')
        })
      }
    },
    computed: {
      pageLocation() {
        return `/data_files/${this.dataFileId}/data_file_scs/${this.dataFileScId}/close_shop_candidates`
      },
      requestIdsKey() {
        return 'close_shop_candidate_ids'
      }
    }
  })
})
