import Vue from 'vue'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('vue-header')
  if (el) {
    new Vue({
      el: el,
      data() {
        return {
          isMenuOpen: false
        }
      },
      mounted() {
        window.addEventListener('click', event => {
          if (!this.isMenuOpen) return
          const clickedElement = event.target
          const menuElement = document.getElementById('header-menu')
          const toggleIconElement = document.getElementById('header-toggle-icon')
          if (!menuElement.contains(clickedElement) && !toggleIconElement.contains(clickedElement)) {
            this.isMenuOpen = false
          }
        })
      },
      methods: {
        toggleMenu() {
          this.isMenuOpen= !this.isMenuOpen
        }
      }
    })
  }
})
