import $ from 'jquery'
import axios from '../plugins/axios'
import _ from 'lodash'
import { noticeState, candidateState } from '../state'
import queryString from 'query-string'

export default {
  data() {
    return {
      dataFileId: null,
      dataFileScId: null,
      candidates: [],
      selectedCandidates: [],
      statusToChange: { value: 'none', translated: '状態なし' },
      currentMenu: 'changeStatus',
      isShowSelectedList: true,
      urlSearchParams: {},
      closeDate: null
    }
  },
  mounted() {
    const initData = $(this.$el).data('init')
    this.candidates = initData.candidates
    candidateState.failureInfos.forEach(failureInfo => {
      const target = this.selectableCandidates.find(candidate => candidate.id === failureInfo.id)
      if (!target) return
      target.message = failureInfo.message
    })
    candidateState.failureInfos = []
    this.dataFileId = initData.dataFileId
    this.dataFileScId = initData.dataFileScId
    this.urlSearchParams = initData.urlSearchParams
    this.closeDate = initData.closeDate
    this.$forceUpdate()
  },
  methods: {
    toggleSelected(event, targetId) {
      const targetCandidate = _.find(this.selectableCandidates, { id: targetId })
      if (!targetCandidate) return
      if (event.target.checked) {
        this.$_addSelectedCandidate(targetCandidate)
      } else {
        this.$_removeSelectedCandidate(targetCandidate)
      }
    },
    selectAll() {
      this.selectedCandidates = _.cloneDeep(this.selectableCandidates)
    },
    unselectAll() {
      this.selectedCandidates = []
    },
    async changeStatus() {
      await axios.post(`${this.pageLocation}/change_status`, {
        [this.requestIdsKey]: this.selectedCandidates.map(c => c.id),
        status: this.statusToChange.value
      }).then(() => {
        this.selectedCandidates.forEach(candidate => {
          candidate.status = Object.assign({}, this.statusToChange)
        })
        this.$_noticeMessage('success', 'ステータスを変更しました。')
      }).catch(() => {
        this.$_noticeMessage('danger', 'エラーによりステータス変更ができませんでした。')
      })
    },
    async addShopNameToDirectory() {
      await axios.post(`${this.pageLocation}/add_shop_name_to_directory`, {
        [this.requestIdsKey]: this.selectedCandidates.map(c => c.id)
      }).then(response => {
        const successCount = _.filter(response.data.data, (key, val) => !!val).length
        const failures = !!response.data.errors
          ? response.data.errors.map(e => `${path[0]}: ${e.message}`)
          : []
        let message = `${successCount}件のディレクトリにショップ名を登録しました。`
        if (!_.isEmpty(failures)) message += `\n登録に失敗したディレクトリがあります。${failures.join(', ')}`
        this.$_visitToPage()
        this.$_noticeMessage('success', message)
      }).catch(() => {
        this.$_noticeMessage('danger', 'エラーによりショップ名登録ができませんでした。')
      })
    },
    async registerIgnoreShop() {
      if (!window.confirm('除外リストに登録しますか？ 登録すると今後このショップはデータ取り込み時に除外されます。')) return
      await axios.post(`${this.pageLocation}/register_ignore_shop`, {
        [this.requestIdsKey]: this.selectedCandidates.map(c => c.id),
      }).then(() => {
        this.$_visitToPage()
        this.$_noticeMessage('success', '除外リスト登録しました。')
      }).catch((error) => {
        this.$_noticeMessage('danger', `除外リスト登録できませんでした。${error.response.data}`)
      })
    },
    $_addSelectedCandidate(targetCandidate) {
      if (_.find(this.selectedCandidates, { id: targetCandidate.id })) return
      this.selectedCandidates.push(targetCandidate)
    },
    $_removeSelectedCandidate(targetCandidate) {
      this.selectedCandidates = this.selectedCandidates.filter(c => c.id !== targetCandidate.id)
    },
    $_visitToPage() {
      let url = this.pageLocation
      if (this.urlSearchParams) {
        let query = {}
        if (this.urlSearchParams['q']) {
          query = _.mapKeys(this.urlSearchParams['q'], (value, key) => `q[${key}]`)
        }
        if (this.urlSearchParams['page']) {
          query['page'] = this.urlSearchParams['page']
        }
        url = queryString.stringifyUrl({ url: url, query: query })
      }
      Turbolinks.visit(url)
    },
    $_noticeMessage(type, message) {
      noticeState.message = message
      noticeState.type = type
      noticeState.isOpen = true
    },
  },
  computed: {
    selectableCandidates() {
      return this.candidates.filter(candidate =>
        ['none', 'opened', 'closed', 'unknown'].includes(candidate.status.value)
      )
    },
    isCandidateSelected() {
      return !_.isEmpty(this.selectedCandidates)
    },
    checked() {
      return targetId => {
        return _.find(this.selectedCandidates, {id: targetId}) ? 'checked' : null
      }
    },
    pageLocation() {
      return ''
    },
    requestIdsKey() {
      return ''
    },
    isExistSelectedCandidates() {
      return !_.isEmpty(this.selectedCandidates)
    },
    message() {
      return targetId => {
        const candidate = _.find(this.selectableCandidates, {id: targetId})
        return candidate ? candidate.message : ''
      }
    },
    canAddShopNameToDirectory() {
      return !this.isDuplicateDirectoryInSelectedCandidates && !this.isNullDirectoryInSelectedCandidates
    },
    isDuplicateDirectoryInSelectedCandidates() {
      return this.selectedCandidates.length !== _.uniqBy(this.selectedCandidates, 'directoryId').length
    },
    isNullDirectoryInSelectedCandidates() {
      return this.selectedCandidates.some(candidate => candidate.directoryId === null)
    },
    duplicatedDirectoryIds() {
      if (this.isDuplicateDirectoryInSelectedCandidates) {
        const uniqIds = _.uniqBy(this.selectedCandidates, 'directoryId').map(candidate => candidate.id)
        return this.selectedCandidates
          .filter(candidate => !uniqIds.includes(candidate.id))
          .map(candidate => candidate.directoryId)
      } else {
        return []
      }
    },
    isWarningDirectoryId() {
      return directory_id => {
        return this.currentMenu === 'addShopName'
          ? this.isNullDirectoryInSelectedCandidates && !directory_id
            ? !directory_id
            : this.isDuplicateDirectoryInSelectedCandidates
              ? this.duplicatedDirectoryIds.includes(directory_id)
              : false
          : false
      }
    },
    statusById(targetId) {
      return targetId => {
        const target = this.candidates.find(candidate => candidate.id === targetId)
        return target ? target.status : ''
      }
    },
    statusBadgeClassById(targetId) {
      return targetId => {
        const candidate = this.candidates.find(candidate => candidate.id === targetId)
        if (!candidate) return 'badge-primary'
        let result = 'badge-primary'
        switch (candidate.status.value) {
          case 'none':
            result = 'badge-secondary'
            break
          case 'opened':
          case 'closed':
            result = 'badge-primary'
            break
          case 'registered':
          case 'nicknamed':
          case 'ignored':
            result = 'badge-success'
            break
          case 'unknown':
            result = 'badge-warning'
            break
        }
        return result
      }
    }
  }
}
