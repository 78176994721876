import axios from 'axios'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  transformRequest: [
    (data, headers) => {
      const resultData = data ? data : {}
      if (!resultData.hasOwnProperty('authenticity_token')) {
        const csrf_token = process.env.RAILS_ENV !== 'test'
          ? document.querySelector('meta[name="csrf-token"]').content
          : null
        resultData['authenticity_token'] = csrf_token
      }
      return JSON.stringify(resultData)
    }
  ]
})

// wait_for_ajaxを実現するためにカウンターを設置
// @see https://gist.github.com/qcam/11489603#gistcomment-3162047
window.pendingRequestCount = 0

instance.interceptors.request.use(config => {
  pendingRequestCount++
  return config
}, Promise.reject)

instance.interceptors.response.use(
  response => {
    pendingRequestCount--
    return response
  },
  error => {
    pendingRequestCount--
    return Promise.reject(error)
  }
)

export default instance