import Vue from 'vue'
import axios from '../plugins/axios'
import _ from 'lodash'
import dataFileScsMixin from '../mixins/data_file_scs_mixin'
import $ from 'jquery'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('vue-data-file-scs')
  if (!el) return
  new Vue({
    el: el,
    mixins: [dataFileScsMixin],
    data() {
      return {
        dataFileId: null,
        status_counts: {
          not_started: 0,
          working: 0,
          completed: 0,
        }
      }
    },
    mounted() {
      const initData = $(this.$el).data('init')
      this.dataFileId = initData.data_file_id
      this.status_counts = initData.status_counts
    },
    computed: {
      publicationDataFromId() {
        return (data_file_sc_id) => {
          const data_file_sc = this.$_getDataFileSc(data_file_sc_id)
          if (data_file_sc) return data_file_sc.publication_data
          return []
        }
      },
      isToggleDataFromId() {
        return (data_file_sc_id) => {
          const data_file_sc = this.$_getDataFileSc(data_file_sc_id)
          if (data_file_sc) return data_file_sc.is_toggle_data
          return false
        }
      },
    },
    methods: {
      async togglePublicationDataFromId(data_file_sc_id) {
        const data_file_sc = this.$_getDataFileSc(data_file_sc_id)
        if (!data_file_sc) return
        if (!data_file_sc.is_toggle_data && _.isEmpty(data_file_sc.publication_data)) {
          await axios.get(`/data_files/${data_file_sc.data_file_id}/data_file_scs/${data_file_sc.id}/publication_data`)
            .then(response => {
              data_file_sc.publication_data = response.data
            })
        }
        data_file_sc.is_toggle_data = !data_file_sc.is_toggle_data
      },
      async $_updateWorkingConditions(data_file_sc) {
        axios.get(
            `/data_files/${data_file_sc.data_file_id}/data_file_scs/working_conditions.json`
        ).then(response => {
          this.status_counts = response.data.status_counts
        }).catch(error => {
          this.$_noticeMessage('作業状況を更新できませんでした', 'danger')
        })
      },
      showFloorName(floor_name) {
        if (floor_name === '※SC名フロア') {
          return floor_name + '(置き換えあり)'
        } else {
          return floor_name
        }
      },
    }
  })
})
