import $ from 'jquery'
import axios from '../plugins/axios'
import _ from 'lodash'
import Turbolinks from 'turbolinks'
import { noticeState } from '../state'

export default {
  data() {
    return {
      data_file_scs: [],
      assignableUsers: [],
      selectedScs: [],
      selectedUserId: ''
    }
  },
  mounted() {
    const initData = $(this.$el).data('init')
    this.data_file_scs = initData.data_file_scs.map(data_file_sc => {
      return { ...data_file_sc, publication_data: [], is_toggle_data: false }
    })
    this.assignableUsers = initData.assignable_users;
  },
  computed: {
    assignee_name() {
      return (data_file_sc_id) => {
        const data_file_sc = this.$_getDataFileSc(data_file_sc_id)

        if (data_file_sc && data_file_sc.assignee) {
          const assignee = data_file_sc.assignee
          if (assignee.status === 'active') {
            return assignee.name
          } else {
            return `${assignee.name}(無効)`
          }
        } else {
          return ''
        }
      }
    },
    status_class() {
      return (data_file_sc_id) => {
        const data_file_sc = this.$_getDataFileSc(data_file_sc_id)
        return data_file_sc ? `row-status_${data_file_sc.status}` : ''
      }
    },
    checked() {
      return targetId => {
        return _.find(this.selectedScs, {id: targetId}) ? 'checked' : null
      }
    },
  },
  methods: {
    async assign(event, dataFileScId) {
      const data_file_sc = this.$_getDataFileSc(dataFileScId)
      if (!data_file_sc) {
        console.error(`不正なSCが選択されています。dataFileScId: ${dataFileScId}`)
        return
      }

      const userId = parseInt(event.target.value, 10)
      const user = this.$_getAssignableUser(userId)
      if (!user) {
        console.error(`不正な担当者が選択されています。userId: ${userId}`)
        return
      }
      await axios.post(`/data_files/${data_file_sc.data_file_id}/data_file_scs/${data_file_sc.id}/assign`, {
        assignee_id: user.id
      }).then(response => {
        data_file_sc.assignee = user
      }).catch(error => {
        this.$_noticeMessage('担当者を登録できませんでした。', 'danger')
      })
    },
    async withdraw(dataFileScId) {
      if (!confirm('本当に解除してよろしいですか？')) return

      const data_file_sc = this.$_getDataFileSc(dataFileScId)
      if (!data_file_sc) {
        console.error(`不正なSCが選択されています。dataFileScId: ${dataFileScId}`)
        return
      }

      await axios.delete(
          `/data_files/${data_file_sc.data_file_id}/data_file_scs/${data_file_sc.id}/withdraw`
      ).then(response => {
        data_file_sc.assignee = null
      }).catch(error => {
        this.$_noticeMessage('担当者を解除できませんでした。', 'danger')
      })
    },
    toggleSelected(event, dataFileScId) {
      const target = _.find(this.data_file_scs, { id: dataFileScId })
      if (!target) return
      if (event.target.checked) {
        this.$_addSelectedSc(target)
      } else {
        this.$_removeSelectedSc(target)
      }
    },
    async assignUserToSelectedScs() {
      if (!confirm('担当を一括登録します。よろしいですか？')) return

      await axios.post('/assigned_data_file_scs/assign_user', {
        user_id: this.selectedUserId,
        data_file_sc_ids: this.selectedScs.map(sc => sc.id)
      }).then(response => {
        this.$_noticeMessage('担当者を登録しました。', 'success')
        Turbolinks.visit(location.href)
      }).catch(error => {
        this.$_noticeMessage(`登録できませんでした。${error.response.data}`, 'danger')
      })
    },
    async withdrawUserFromSelectedScs() {
      if (!confirm('本当に解除してよろしいですか？')) return

      await axios.post('/assigned_data_file_scs/withdraw_user', {
        data_file_sc_ids: this.selectedScs.map(sc => sc.id)
      }).then(response => {
        this.$_noticeMessage('担当者を解除しました。', 'success')
        Turbolinks.visit(location.href)
      }).catch(error => {
        this.$_noticeMessage(`担当者を解除できませんでした。${error.response.data}`, 'danger')
      })
    },
    async changeStatus(event, dataFileScId) {
      const data_file_sc = this.$_getDataFileSc(dataFileScId)
      if (!data_file_sc) return

      const status = event.target.value
      await axios.put(
          `/data_files/${data_file_sc.data_file_id}/data_file_scs/${data_file_sc.id}/status/${status}`
      ).then( async response => {
        data_file_sc.status = status
        await this.$_updateWorkingConditions(data_file_sc)
      }).catch(error => {
        this.$_noticeMessage('作業状態を変更できませんでした', 'danger')
      })
    },
    selectAll() {
      this.selectedScs = _.cloneDeep(this.data_file_scs)
    },
    unselectAll() {
      this.selectedScs = []
    },
    async $_updateWorkingConditions(data_file_sc) {},
    $_getDataFileSc(dataFileScId) {
      return this.data_file_scs.find(data_file_sc => data_file_sc.id === dataFileScId)
    },
    $_getAssignableUser(userId) {
      return this.assignableUsers.find(user => user.id === userId)
    },
    $_addSelectedSc(target) {
      if (_.find(this.selectedScs, { id: target.id })) return
      this.selectedScs.push(target)
    },
    $_removeSelectedSc(target) {
      this.selectedScs = this.selectedScs.filter(c => c.id !== target.id)
    },
    $_noticeMessage(message, type) {
      noticeState.message = message
      noticeState.type = type
      noticeState.isOpen = true
    },
  }
}
